<template>
  <div class="main-login" :style="esProduccion()?'':'background-color: orangered !important;background: radial-gradient(ellipse at center, #bd3601 1%, #5b1701 100%) !important;'">
    <div class="container">
      <center>
        <div class="middle">
          <div id="login">
            <CForm method="POST" ref="formLogin" @submit.prevent="login">

              <fieldset class="clearfix">
                <div class="text-white d-block d-sm-none">
                  <img class="img-responsive mb-3" src="img/ub-blanco.png" width="180px"/>
                  <div class="clearfix"></div>
                </div>
                <p>
                  <span class="fa fa-user"><font-awesome-icon :icon="['fas', 'user']"/></span>
                  <input type="text" required="required"
                         v-model="email"
                         Placeholder="Usuario"/>
                  <div class="invalid-tooltip">El nombre de usuario es requerido</div>
                </p>
                <p>
                  <span class="fa fa-lock"><font-awesome-icon :icon="['fas', 'lock']"/></span>
                  <input type="password"
                         Placeholder="Contraseña"
                         required="required"
                         v-model="password"
                         autocomplete="curent-password"
                  >
                  <div class="invalid-tooltip">La contraseña es requerida</div>
                </p>
                <div>
                  <span style="width:50%; text-align:right;  display: inline-block;">
                    <input type="submit" value="Ingresar"></span>
                </div>

              </fieldset>
              <div class="clearfix"></div>
            </CForm>
          </div>
          <div class="logo">
            <img class="img-responsive d-none d-md-block" src="img/ub-blanco.png" width="200px"/>
            <div class="clearfix"></div>
          </div>

        </div>
      </center>
      <div id="adsgoeshere" style="background-color: transparent;margin-top: 50px; padding-top:10px; text-align: center;" v-html="adsenseContent"></div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      showMessage: false,
      message: '',
      adsenseContent:''
    }
  },
  methods: {
    esProduccion(){
      return (window.location.host==='ubacademico.vgm.tecnologia.bo');
    },
    login() {
      const self = this;
      if (!self.$refs.formLogin.checkValidity()) {
        self.$refs.formLogin.classList.add('was-validated');
      } else {
        axios.post(this.$apiAdress + '/api/login', {
          email: self.email.includes("@") ? self.email : self.email + '@ub.edu.bo',
          password: self.password,
        }).then(function (response) {
          self.email = '';
          self.password = '';
          localStorage.setItem("api_token", response.data.access_token);
          localStorage.setItem('roles', response.data.roles);
          localStorage.setItem('username', response.data.name);
          localStorage.setItem('docente', response.data.docente);
          localStorage.setItem('eligesede', true);
          // if(response.data.docente){
          //   self.$router.push({path: 'docenteregistronotas'});
          // }else {
          self.$router.push({path: 'dashboard'});
          //}
        }).catch(function (error) {
          self.message = 'Usuario o contraseña incorrectos';
          self.showMessage = true;
          console.log(error);
        });
      }
    }
  },
  beforeCreate() {

  },
  mounted() {
    if(window.location.host!='ubacademico.vgm.tecnologia.bo') {
      this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML
    }
  }
}

</script>

<style lang="scss">
@import '../../assets/scss/login';
</style>